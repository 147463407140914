import React, { useEffect, useRef } from "react";
import { DRAGGABLE_BOX_TYPE } from "../utils/constants";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

export default function DragBox({
  box: { imgName, step },
}: {
  box: { imgName: string; step: number };
}) {
  const imgRef = useRef<any>(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type: DRAGGABLE_BOX_TYPE,
    item: { step, imgName },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  useEffect(() => {
    preview(getEmptyImage());
  }, []);

  return (
    <div
      ref={drag}
      key={imgName}
      className={`box ${isDragging ? "dragging" : ""} ${imgName}`}
    >
      <img ref={imgRef} src={`/assets/images/${imgName}.png`} />
    </div>
  );
}
