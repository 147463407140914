import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, DROPPABLE_BOXES, STEPS } from "../utils/constants";
import DropBox from "./DropBox";
import DragBox from "./DragBox";
import Nav from "./Nav";
import Video from "./Video";

export default function PuzzleGame() {
  const [puzzleBoxes, setPuzzleBoxes] = useState<Box[]>(DROPPABLE_BOXES);
  const [resetBtnVisibility, setResetBtnVisibility] = useState<boolean>(false);

  const updatePuzzleBox = useCallback(
    (box: Box, newContent: string) => {
      setPuzzleBoxes((boxes) => {
        return boxes.map((b) => {
          if (b.step === box.step && !b.isPass) {
            return { step: b.step, imgName: newContent, isPass: true };
          }
          return b;
        });
      });
    },
    [puzzleBoxes]
  );

  const resetPuzzleBoxes = useCallback(() => {
    setPuzzleBoxes(DROPPABLE_BOXES);
    setResetBtnVisibility(false);
  }, []);

  const unSelectedSteps = useMemo(() => {
    const passedBoxes = puzzleBoxes
      .filter((box) => box.isPass)
      .map((box) => box.step);

    return STEPS.filter((answerBox) => !passedBoxes.includes(answerBox.step));
  }, [puzzleBoxes]);

  useEffect(() => {
    if (!unSelectedSteps.length)
      (document.getElementById("vid") as any)?.play();
  }, [unSelectedSteps.length]);

  if (!unSelectedSteps.length) {
    return (
      <Video
        resetBtnVisibility={resetBtnVisibility}
        setResetBtnVisibility={setResetBtnVisibility}
        resetPuzzleBoxes={resetPuzzleBoxes}
      />
    );
  }

  return (
    <section className="main-content">
      <Nav />
      <article className=" game-container">
        <section className="droppable-boxes-container">
          {puzzleBoxes.map((puzzleBox) => (
            <DropBox
              key={puzzleBox.imgName}
              box={puzzleBox}
              updatePuzzleBox={updatePuzzleBox}
            />
          ))}
        </section>

        <section className="draggable-boxes-container">
          {unSelectedSteps.map((stepBox) => (
            <DragBox key={stepBox.imgName} box={stepBox} />
          ))}
        </section>
      </article>
    </section>
  );
}
