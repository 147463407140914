export type Box = {
  step: number;
  imgName: string;
  isPass?: boolean;
};

export const DROPPABLE_BOXES: Box[] = [
  { step: 1, imgName: "step_1", isPass: false },
  { step: 2, imgName: "step_2", isPass: false },
  { step: 3, imgName: "step_3", isPass: false },
  { step: 4, imgName: "step_4", isPass: false },
];

export const DRAGGABLE_BOX_TYPE = "DRAGGABLE_BOX_TYPE";

export type DRAGGED_BOX = {
  step: number;
  imgName: string;
};

export const STEPS: Box[] = [
  { step: 3, imgName: "step_3" },
  { step: 1, imgName: "step_1" },
  { step: 2, imgName: "step_2" },
  { step: 4, imgName: "step_4" },
];

export const RIGHT_ANSWER_SCORE = 5;
export const WRONG_ANSWER_SCORE = 1;
