import React from "react";
import { useDrop } from "react-dnd";
import { Box, DRAGGABLE_BOX_TYPE, DRAGGED_BOX } from "../utils/constants";

export default function DropBox({
  box: { imgName, step, isPass },
  updatePuzzleBox,
}: {
  box: Box;
  updatePuzzleBox: Function;
}) {
  const [collectedProps, drop] = useDrop(() => ({
    accept: DRAGGABLE_BOX_TYPE,
    drop: (droppedItem: DRAGGED_BOX) => {
      if (!isPass) {
        if (droppedItem.step === step) {
          new Audio("/assets/sounds/success.mp3").play();
          updatePuzzleBox({ imgName, step }, droppedItem.imgName);
        } else {
          new Audio("/assets/sounds/wrong.mp3").play();
        }
      }
    },
  }));
  return (
    <div ref={drop} key={imgName} className="box">
      {isPass && (
        <img
          className={imgName}
          draggable={false}
          src={`/assets/images/${imgName}.png`}
        />
      )}
    </div>
  );
}
