import React from "react";
import { usePreview } from "react-dnd-preview";

export default function DraggingPreview() {
  const { display, itemType, item, style }: any = usePreview();
  if (!display) {
    return null;
  }
  return (
    <div className="preview-container" style={style}>
      <img src={`/assets/images/${item.imgName}.png`} />
    </div>
  );
}
