import React from "react";

export default function Video({
  resetBtnVisibility,
  setResetBtnVisibility,
  resetPuzzleBoxes,
}: {
  resetBtnVisibility: boolean;
  setResetBtnVisibility: Function;
  resetPuzzleBoxes: Function;
}) {
  return (
    <section className="video-container">
      <video
        id="vid"
        autoPlay
        onEnded={() => {
          setResetBtnVisibility(true);
        }}
      >
        <source src={`/assets/final.mp4`} type="video/mp4" />
        Your browser does not support the videos.
      </video>

      {resetBtnVisibility && (
        <button
          onClick={() => {
            resetPuzzleBoxes();
          }}
        >
          <i className="fa fa-rotate-right"></i>
        </button>
      )}
    </section>
  );
}
